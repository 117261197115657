import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@fuji-ui/core';
import styled from 'styled-components';
import is from 'styled-is';
import { themeGet } from '@styled-system/theme-get';
const Burger = styled(Box)`
  z-index: 1000;
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: ${themeGet('colors.onBackground')};
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }
    &:nth-child(4) {
      top: 16px;
    }
    ${is('opened')`
      &:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
    `}
  }
`;

const HamburgerMenu = (props) => {
  // const [opened, setOpened] = useState(false);
  const { opened, onClick } = props;

  // const toggle = () => setOpened(!opened);

  return (
    <Burger onClick={onClick} opened={opened} {...props}>
      <span />
      <span />
      <span />
      <span />
    </Burger>
  );
};

HamburgerMenu.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HamburgerMenu;

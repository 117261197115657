import React from 'react';
import { Box } from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import FullLinks from './links';

const FooterNav = styled(Box)`
  margin: ${themeGet('space.m')};
  nav {
    h5 {
      line-height: 24px;
    }
    ul {
      margin: 0;
      padding: 1rem 0;
      li {
        list-style-type: none;
        font-size: ${themeGet('fontSizes.small')};
        margin-top: ${themeGet('space.s')};
      }
    }
    a {
      text-decoration: none;
      border-bottom: 0;
    }
  }
  .social-icon {
    margin-left: ${themeGet('space.s')};
  }
`;

// eslint-disable-next-line react/display-name
export default ({ set }) => {
  return (
    <FooterNav>
      <FullLinks set={set} />
    </FooterNav>
  );
};

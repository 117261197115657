/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/style/theme.css';
import './src/style/global.css';

import { Box, Button, Card, Column, Row, Typography as T } from '@fuji-ui/core';
import { ThemeProvider } from '@fuji-ui/theme';
import { MDXProvider } from '@mdx-js/react';
import React from 'react';

import Footer from './src/components/footer';
import Header from './src/components/header';
import { Layout } from './src/components/layout';
import image_whatsapp_button from './src/images/image_whatsapp_button.png';
import siteLogoImg from './src/images/teamwork_logo_word.svg';
import theme from './src/style/theme';

const FujiComps = {
  h1: T.H1,
  h2: T.H2,
  h3: T.H3,
  h4: T.H4,
  h5: T.H5,
  h6: T.H6,
  p: T.P,
  T,
  Button,
  Box,
  Row,
  Column,
  Card,
  ul: (props) => <ul className="plain" {...props} />,
};

const SiteLogo = () => (
  <Box as="img" src={siteLogoImg} width={[120, 180, null, null]} />
);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <MDXProvider components={FujiComps}>{element}</MDXProvider>
  </ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  return (
    <Layout {...props}>
      <Header siteLogo={<SiteLogo />} />
      <Box py="m">{element}</Box>
      <Box position="fixed" bottom={0} right={0}>
        <a
          className="talkWithUs"
          href="https://api.whatsapp.com/send/?phone=85267152566"
          target="_blank"
          rel="noreferrer"
          style={{
            border: 'none',
          }}
        >
          <img
            alt="talk with us"
            src={image_whatsapp_button}
            width="80"
            height="80"
          />
        </a>
      </Box>
      <Footer />
    </Layout>
  );
};

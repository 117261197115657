import React, { useState, useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import {
  Box,
  Row,
  Typography as T,
  Button,
  Drawer,
  StickyBox,
} from '@fuji-ui/core';
import { Wrapper } from './layout';
import PropTypes from 'prop-types';
import HamburgerMenu from './hamburger-menu';
import HeaderNav from './nav/header-nav';
import Footer from './footer';

const Header = ({ siteLogo }) => {
  const [opened, setOpened] = useState(false);

  const renderNav = () => (
    <>
      <HamburgerMenu
        display={['block', 'none', null, null]}
        opened={opened}
        onClick={() => setOpened(!opened)}
      />
      <HeaderNav display={['none', 'flex', null, null]} />
    </>
  );
  return (
    <StickyBox
      as="header"
      pt="m"
      pb="s"
      zIndex="10"
      bg="rgb(255,255,255,0.95)"
      top={0}
    >
      <Drawer opened={opened}>
        <Box height="100%" overflow="auto">
          <Footer setOpened={setOpened} />
        </Box>
      </Drawer>
      <Wrapper>
        <Row alignItems="center" justifyContent="space-between">
          <Box>
            <Link to="/" className="logo">
              {siteLogo}
            </Link>
          </Box>
          {renderNav()}
        </Row>
      </Wrapper>
    </StickyBox>
  );
};

Header.propTypes = {
  siteLogo: PropTypes.node,
};

export default Header;

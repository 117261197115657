import React from 'react';
import { Box, Flex, Typography as T } from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'gatsby';

import imgFb from '../../images/if_facebook_circle.svg';
import imgTwitter from '../../images/if_twitter_circle.svg';
import imgYoutube from '../../images/if_youtube_circle.svg';

const NavWrapper = styled(Box)`
  margin: ${themeGet('space.m')};
  h5 {
    line-height: 24px;
  }
  ul {
    margin: 0;
    padding: 1rem 0;
    li {
      list-style-type: none;
      margin-top: ${themeGet('space.s')};
    }
  }
  a {
    text-decoration: none;
    border-bottom: 0;
  }
`;

const Links = ({ set }) => {
  return (
    <Flex
      alignItems="stretch"
      flexDirection={['column', null, 'row', null]}
      mt="l"
    >
      <NavWrapper as="nav" flex={1}>
        <T.H5>Teamwork App</T.H5>
        <ul>
          <li>
            <Link to="/features" onClick={() => set(false)}>
              Features
            </Link>
          </li>
          <li>
            <Link to="/pricing" onClick={() => set(false)}>
              Pricing
            </Link>
          </li>
          <li>
            <Link to="/about-us" onClick={() => set(false)}>
              Our team
            </Link>
          </li>
        </ul>
      </NavWrapper>
      <NavWrapper as="nav" flex={1}>
        <T.H5>Our products</T.H5>
        <ul>
          <li>
            <a href="https://teamworkschool.com" target="_blank">
              Teamwork for School
            </a>
          </li>
          <li>
            <a href="https://grick.io" target="_blank">
              Grick
            </a>
          </li>
          <li>
            <a href="https://enlist.cc" target="_blank">
              Enlist
            </a>
          </li>
        </ul>
      </NavWrapper>
      <NavWrapper as="nav" flex={1}>
        <T.H5>Resources</T.H5>
        <ul>
          <li>
            <a href="https://dev.talkbox.net/browse/zh/document/teamwork-user-guide/teamwork-user-guide">
              User guides
            </a>
          </li>
          <li>
            <Link to="/contact-us" onClick={() => set(false)}>
              Contact us
            </Link>
          </li>
          <li>
            <a href="https://help.teamworkapp.co/">Help Center</a>
          </li>
          <li>
            <a href="https://www.talkboxapp.com/enterprise/login">
              Developer portal
            </a>
          </li>
        </ul>
      </NavWrapper>
      <NavWrapper>
        <Box>
          <a href="https://www.facebook.com/Teamwork-App-2209344859340935/">
            <img className="social-icon" width={24} height={24} src={imgFb} />
          </a>
          <a href="https://twitter.com/talkboxapp">
            <img
              className="social-icon"
              width={24}
              height={24}
              src={imgTwitter}
            />
          </a>
          <a href="https://www.youtube.com/channel/UCoM5ThcqOcqaS1ddmeGGB9g">
            <img
              className="social-icon"
              width={24}
              height={24}
              src={imgYoutube}
            />
          </a>
        </Box>
        <ul>
          <li>
            <Link to="/terms" onClick={() => set(false)}>
              Terms
            </Link>
          </li>
          <li>
            <Link to="/privacy" onClick={() => set(false)}>
              Privacy
            </Link>
          </li>
          <li>
            <Link to="/cookies-policy" onClick={() => set(false)}>
              Using Cookies
            </Link>
          </li>
          <li>
            <Link to="/gdpr" onClick={() => set(false)}>
              GDPR Commitment
            </Link>
          </li>
        </ul>
      </NavWrapper>
    </Flex>
  );
};

export default Links;

import { Box, Typography as T, Separator } from '@fuji-ui/core';
import { Wrapper } from './layout';
import React from 'react';

import FooterNav from './nav/footer-nav';

const Footer = (props) => {
  const set = props?.setOpened;
  return (
    <Box as="footer" py="xl">
      <Wrapper>
        <Separator />
        <FooterNav set={set} />
        <Box textAlign="center" mt="xl">
          <T.H6>❤️ crafted by Talkbox</T.H6>
          <T.Span fontSize="xs">
            COPYRIGHT © {new Date().getFullYear()} TALKBOX LTD.
          </T.Span>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default Footer;

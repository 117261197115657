import React from 'react';
import { Box, Row } from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Layout = styled(Box)`
  ${'' /* overflow-x: hidden; */}
`;

// Page wrapper for normal pages
export const Wrapper = (props) => {
  return (
    <Box
      m="auto"
      width={['100%', '100%', '95%', '90%']}
      maxWidth="1280px"
      px={['m', 'm', 'l', 'xl']}
      {...props}
    />
  );
};

// Page wrapper for documents (i.e. mdx files)
export const DocWrapper = (props) => <Wrapper mt="80px" {...props} />;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Typography as T, Button } from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link, navigate } from 'gatsby';

const HeaderNavStyle = styled(Row)`
  align-items: center;
  a {
    color: ${themeGet('colors.onBackground')};
    border-bottom: 2px solid ${themeGet('colors.onBackground')};
  }
  a:hover {
    opacity: 0.8;
    color: ${themeGet('colors.onBackground')};
  }
  > * {
    margin: 0;
    margin-left: 1rem;
  }
`;

const HeaderNav = (props) => (
  <HeaderNavStyle as="nav" {...props}>
    <T.H5>
      <Link to="/features">Features</Link>
    </T.H5>
    <T.H5>
      <Link to="/pricing">Pricing</Link>
    </T.H5>
    <T.H5>
      <a href="/about-us">Our Team</a>
    </T.H5>
    <Button onClick={() => navigate('/contact-us')}>Contact us</Button>
  </HeaderNavStyle>
);

export default HeaderNav;
